.input-container {
  display: contents;
}

.no-flex-direction {
  flex-direction: unset;
}

.text-align-dialog {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.custom-error {
  color: #fd397a;
  margin: 4px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1em;
}

.copied-url-tooltip {
  color: 'lightblue';
  background-color: 'green';
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.agency-logo-container {
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.agency-logo {
  max-height: -webkit-fill-available;
  margin-left: 10px;
}

.agency-title-logo {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 500;
  color: #434349;
  display: flex;
  align-items: center;
}

.kt-splash-screen {
  text-align: center !important;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.spinner-karlo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

//disable scroll on input number /* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
