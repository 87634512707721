//
// Quill
//

// Base
.ql-toolbar.ql-snow {
  border: 1px solid kt-base-color(grey, 2);

  @include kt-rounded {
    border-top-left-radius: $kt-border-radius;
    border-top-right-radius: $kt-border-radius;
  }

  button {
    &.ql-active,
    &:focus,
    &:hover {
      .ql-stroke {
        stroke: kt-brand-color();
      }
    }
  }

  .ql-picker-label {
    &.ql-active,
    &:hover {
      color: kt-brand-color();
    }
  }

  .ql-picker-item {
    &.ql-selected,
    &.ql-active,
    &:hover {
      color: kt-brand-color();
    }
  }
}

.ql-container.ql-snow {
  border: 1px solid kt-base-color(grey, 2);
  border-top: 0;

  @include kt-rounded {
    border-bottom-left-radius: $kt-border-radius;
    border-bottom-right-radius: $kt-border-radius;
  }
}

.ql-snow {
  .ql-picker {
    &.ql-expanded {
      outline: none !important;
      border-color: transparent !important;

      .ql-picker-label {
        border-color: transparent !important;
        color: kt-brand-color();
        outline: none !important;

        &.ql-active,
        &:hover {
          color: kt-brand-color();
        }
      }

      .ql-picker-options {
        border: 0;
        padding: 0.5rem 1rem;
        box-shadow: $dropdown-box-shadow;

        @include kt-rounded {
          border-radius: $kt-border-radius;
        }
      }
    }
  }

  .ql-tooltip {
    border: 0;
    padding: 0.5rem 1rem;
    box-shadow: $dropdown-box-shadow;

    @include kt-rounded {
      border-radius: $kt-border-radius;
    }

    input[type='text'] {
      @include kt-input-reset();
      border: 1px solid kt-base-color(grey, 2);
      color: $input-plaintext-color;
      outline: none !important;

      @include kt-rounded {
        border-radius: $kt-border-radius;
      }

      &:active,
      &:focus {
        border-color: $input-focus-border-color !important;
      }
    }

    .ql-preview {
      color: kt-base-color(label, 2);
    }

    .ql-action {
      transition: color 0.3s ease;
      color: kt-base-color(label, 2);

      &:hover {
        transition: color 0.3s ease;
        color: kt-brand-color();
      }
    }

    // Tooltip adjustment in modal
    .modal & {
      &.ql-editing {
        left: 20px !important;
      }
    }
  }

  .ql-editor {
    color: kt-base-color(label, 2);
  }

  .ql-editor pre.ql-syntax {
    background-color: #333;
    color: #f8f8f2;
    overflow: visible;

    @include kt-rounded {
      border-radius: $kt-border-radius;
    }
  }
}
